<template>

    <div>
      <div class="row mb-2">
        <div class="col-sm-4">
          <router-link to="/rentals/add-car" class="btn btn-danger mb-2"
          ><i class="mdi mdi-plus-circle mr-1"></i> Add New Car </router-link
          >
        </div>
        <div class="col-sm-4">
            <div
                id="tickets-table_filters"
                class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Filters:
                <select class="form-control form-control-sm ml-2" >
                  <option value=""> All </option>
                  <option value="available"> Available </option>
                  <option value="in-use"> Rented </option>
                  <option value="in-progress"> Processing </option>
                </select>
              </label>
            </div>

        </div>
        <div class="col-sm-4">
          <div class="float-sm-right">
            <form class="form-inline">
              <div class="form-group mr-2">
                <label for="productssearch-input" class="sr-only">Search</label>
                <input
                    type="search"
                    class="form-control border-light"
                    id="productssearch-input"
                    placeholder="Search..."
                />
              </div>
              <button type="button" class="btn btn-success mb-2 mb-sm-0">
                <i class="mdi mdi-cog"></i>
              </button>
            </form>
          </div>
        </div>
        <!-- end col-->
      </div>
      <!-- end row -->

      <div class="row">
        <div
            class="col-md-6 col-xl-3"
            v-for="(item, index) in products"
            :key="index"
        >
          <div class="card product-box">
            <div class="product-img">
              <div class="p-3">
                <img :src="`${item.mainImage}`" alt="product-pic" style="height: 150px; width: 200px; object-fit: contain" class="img-fluid" />
              </div>
              <div class="product-action">
                <div class="d-flex">
                  <router-link :to="'/rentals/car-detail/' + item.id"
                    class="btn btn-white btn-block action-btn m-2"
                  >
                    <i class="ri-edit-2-fill align-middle"></i> Details
                  </router-link>
                  <a v-if="item.status === 'available'"
                      @click="onCarRemoveTapped(item.id)"
                      href="javascript: void(0);"
                      class="btn btn-white btn-block action-btn m-2"
                  ><i class="ri-delete-bin-fill align-middle"></i> Delete </a
                  >
                </div>
              </div>
            </div>

            <div class="product-info border-top p-3">
              <div>
                <h5 class="font-16 mt-0 mb-1">
                  <router-link
                      :to="`/ecommerce/product-detail/${item.id}`"
                      class="text-dark"
                  >{{ item.name }}</router-link
                  >
                </h5>
                <p class="text-muted mt-4">
                  <span v-if="item.status === 'available' " class="badge badge-success p-1">{{ item.status }} </span>
                  <span v-if="item.status === 'in-progress' " class="badge badge-warning p-1 text-black-50" >{{ 'Processing' }} </span>
                  <span v-if="item.status === 'in-use' " class="badge badge-danger p-1" >{{ item.status }} </span>
                </p>
                <h5 class="m-0" v-if="item['current_assigned_to']">
                  <span class="text-success"> assigned to: {{item['current_assigned_to']['name']}} {{item['current_assigned_to']['other_names']}} </span>
                </h5>
                <div class="mt-2" v-if="item['current_assigned_to']">
                  <div class="row">
                    <div class="col-md-12" >
                      <span class="badge badge-outline-dark badge-pill mr-2" v-if="item['driver']"> dossier: {{item['driver']['dossier']}} </span>
                      <span class="badge badge-outline-info badge-pill" v-if="item['prospectiveDriver']">{{item['prospectiveDriver']['service_type'] | serviceType}} </span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- end product info-->
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-12">
          <ul class="pagination pagination-rounded justify-content-end mb-3">
            <li class="page-item">
              <a
                  class="page-link"
                  href="javascript: void(0);"
                  aria-label="Previous"
              >
                <span aria-hidden="true">«</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item active">
              <a class="page-link" href="javascript: void(0);">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript: void(0);">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript: void(0);">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript: void(0);">4</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript: void(0);">5</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript: void(0);" aria-label="Next">
                <span aria-hidden="true">»</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- end col-->
      </div>
      <!-- end row-->
    </div>

</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import appConfig from "../../../../../app.config.json";
import {confirm} from "../../../../utils/functions";

/**
 * Products-grid component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    products(){
      return this.$store.getters.GET_CARS
    },
  },
  methods: {
    onCarRemoveTapped(carId, status){
      confirm('Remove this car from the system', () => {
          this.$store.dispatch('removeCar', carId)
      })
    }
  },
  created() {
    this.$store.dispatch('getCars');
    this.$store.dispatch('setPageTitle','Show Room');
  }
};
</script>

